import React from 'react';
import Button from "../Button";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';

const ValuePropAPropos = props => {

  return (
    <section className="value-prop-a-propos">
      <div className="value-prop-content-a-propos">
        <div className="main-message-a-propos">
          <p className="main-abovetitle-a-propos"><Link to="/" className="main-abovetitle-a-propos">Accueil</Link> &#62; A propos</p>
          <h1>Une squad de développeurs fans de nouvelles technologies</h1>
          <p className="main-subtitle-a-propos">Situé à Tours, à Mame le lieu dédié à la création et au numérique dans le cadre de l'écosystème French Tech local. Nous pouvons également nous déplacer à Paris, Nantes, Orléans, Laval et Rennes. <br/> Nous pouvons répondre à vos besoins au forfait ou à la régie selon vos besoins.</p>
        </div>
      </div>
    </section>
  )
};

export default ValuePropAPropos;
