import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import '../../styles/components/a-propos/_social-proof-a-propos.scss';

const SocialProofAPropos = props => {

  return (
    <section className="social-proof-a-propos">
    <h2>Une équipe multi-récompensée</h2>

      <div className="div-block-about-a-propos">
        <div className="w-layout-grid-a-propos grid-about-a-propos">
          <div className="div-block-about-a-propos-2">
            <StaticImage src="../../assets/a-propos/concours-lepine.png" className="image-a-propos-contest" alt="Concours Lépine TLabs" objectFit="contain" placeholder ="blurred"/>
            <p class="paragraph-about-a-propos">Double médaillé d'or du concours Lépine</p>
          </div>
          <div className="div-block-about-a-propos-2">
            <StaticImage src="../../assets/a-propos/dassault-systems.jpg" className="image-a-propos-contest" alt="Dassault Systems TLabs" objectFit="contain" placeholder ="blurred"/>
            <p class="paragraph-about-a-propos">Lauréat Smart-City par Dassault Systems</p>
          </div>
          <div className="div-block-about-a-propos-2">
            <StaticImage src="../../assets/a-propos/french-iot.jpg" className="image-a-propos-contest" alt="French IOT La Poste TLabs" objectFit="contain" placeholder ="blurred"/>
            <p class="paragraph-about-a-propos">Lauréat French IOT par La Poste</p>
          </div>
          <div className="div-block-about-a-propos-2">
            <StaticImage src="../../assets/a-propos/nuit-de-linfo.jpg" className="image-a-propos-contest" alt="Nuit de l'info TLabs" objectFit="contain" placeholder ="blurred"/>
            <p class="paragraph-about-a-propos">3 médailles d'or Nuit de l'info</p>
          </div>
        </div>
      </div>

      {/* 

      <div className="social-proof-icons">
        <StaticImage src="../assets/social-proof/bpi.png" alt="BPI France" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/cdd.png" alt="Caisse des dépots" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/cvl.png" alt="Centre Val de Loire" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/LaFrenchTech.png" alt="La French Tech" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/LaPoste.png" alt="La Poste" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/ligueEnseignement.png" alt="Ligue de l'enseignement" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/pepite.png" alt="PEPITE" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/tours.png" alt="Ville de Tours" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/ToursLoireValley.png" alt="Tours Loire Valley" objectFit="contain" placeholder ="blurred"/>
        <StaticImage src="../assets/social-proof/vivalab.png" alt="Vivalab" objectFit="contain" placeholder ="blurred"/>
      </div>
      */}
    </section>
  )
};

export default SocialProofAPropos;
