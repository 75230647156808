import React from "react";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ValuePropAPropos from "../../components/a-propos/ValuePropAPropos";
import ProductBenefitsAPropos from "../../components/a-propos/ProductBenefitsAPropos";
import SocialProofAPropos from "../../components/a-propos/SocialProofAPropos";
import Testimonials from "../../components/Testimonials";

const AProposPage = () => (
  <Layout>
    <SEO title="Développement d'applications web et mobiles" />
    <ValuePropAPropos />
    <SocialProofAPropos/>
    <ProductBenefitsAPropos />
    <Testimonials />
  </Layout>
);

export default AProposPage;