import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import BorderButton from "../BorderButton"

const ProductBenefitsAPropos = props => {

  return (
    <section className="product-benefits-a-propos">
      <div className="product-benefits-group-a-propos">
        <div className="product-benefit-1-tagline-a-propos">
          <h3>Plus de 10 réalisations </h3>
          <p>Nous avons pu réaliser plus de 10 projets, aussi bien des applications web que des applications mobiles.</p>
          <Link to="/portfolio"><BorderButton label="Découvrir notre portfolio &#8594;"></BorderButton></Link>
        </div>
        <div className="product-benefit-1-image-a-propos">
          <StaticImage src="../../assets/a-propos/realisations.png" alt="Réalisations TLabs" objectFit="contain" placeholder ="blurred"/>
        </div>
      </div>
    </section>
  )
};

export default ProductBenefitsAPropos;
